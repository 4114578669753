.wish-list-icon{
    cursor: pointer;
}


.product-tile{
    color: $gray-600;
    &:hover{
        text-decoration: none;
        color: $gray-600;
    }
    h3{
        small{
            font-size: 40%;
        }   
        span.mrp{
            text-decoration: line-through;
        }
    }
}

.product-price{
    small{
        font-size: 80%;
    }
    span.mrp{
        text-decoration: line-through;
    }
}