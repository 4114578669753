.navbar{
    margin-bottom: 20px;
    .nav-item{
        a{
            color: $gray-900;
        }
    }
    a.navbar-brand{
        color: $gray-900;
    }
    &.bg-primary,&.bg-danger,&.bg-dark{
        .nav-item{
            a{
                color: $gray-100;
            }
        }
        a.navbar-brand{
            color: $gray-100;
        }
    }
}
