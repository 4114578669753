.banner-container{
    .banner-item{
        position: relative;
        width: 100%;
        height: 50vh;
        overflow: hidden;
        img{
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }
        .content{
            position: absolute;
            top: 10%;
            bottom: 10%;
            z-index: 2;
            left: 10%;
            right: 10%;
            padding: 20px;
            background: rgb(0 0 0 / 60%);
            color: #fff;
            p{
                position: absolute;
                bottom: 5%;
                width: 70%;
            }
            a.btn{
                position: absolute;
                bottom: 5%;
                right: 20px;
            }
        }
    }
}

@media screen and (max-width: $width-tablet-max) {

}

@media screen and (max-width: $width-phone-min) {
    .banner-container{
        .banner-item{
            height: 75vh;
            .content{
                p{
                    position: relative;
                    margin-top: 50px;
                    width: 100%;
                }
            }
        }
    }
}